<template>
    <v-card>
        <v-card-title
          primary-title
          class="d-flex justify-space-between blueGrayMinsal--text"
        >
          Users
          <div>
            <v-btn
            v-if="permission"
              class="ma-1"
              @click="$router.push('/users/create')"
              ><v-icon left>mdi-plus</v-icon>Add</v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader v-if="loading == true"></v-skeleton-loader>
          <v-data-table
            :headers="headers"
            :items="users"
            item-key="id"
            class="elevation-0 border-1"
            no-data-text="No hay datos"
            no-results-text="No hay resultados"
            :footer-props="{
              'items-per-page-options': [5, 10, 20],
              'items-per-page-text': 'Filas',
              'page-text': '',
            }"
            v-if="users.length > 0 && loading == false && permission === true"
            :key="dataTableKey"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.accion`]="{ item }">
                <v-btn icon small @click="setDeleted(item)"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
              <v-btn icon small @click="editUser(item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
          </v-data-table>
          <v-alert
            icon="mdi-information"
            prominent
            text
            v-else
          >
            No data available</v-alert
          >
          <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Delete user {{selectedDelete.name}}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="deleteUser(selectedDelete)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '@axios'
import VueAlertify from 'vue-alertify'

export default {
  data() {
    return {
      roles: [],
      users: [],
      selectedDelete: [],
      dataTableKey: 0,
      isLoaded: false,
      loading: false,
      dialog: false,
      permission: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Email',
          align: 'start',
          value: 'email',
        },
        { text: 'Action', value: 'accion', sortable: false, width: '100' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    ...mapActions({
      getUser: 'auth/getUser',
      getAllUsers: 'auth/getAllUsers',
    }),
    setDeleted(user) {
      this.dialog = true
      this.selectedDelete = user
    },
    getRole() {
      this.loading = true
      if (this.hasUserRole('Super Admin') || this.hasUserRole('Client Owner')) {
        this.getUsers()
      } else {
        this.loading = false
        this.$router.push({ name: 'misc-not-authorized' })
      }
    },
    getUsers() {
      axios
        .get('user/all')
        .then(response => {
          this.users = response.data
          this.loading = false
          console.log(this.users)
          this.permission = true
          if (this.users.length === 0) {
          }
        })
        .catch(() => {
          this.$alertify.error('Unable to get users')
        })
    },
    deleteUser(user) {
      axios
        .post('/user/delete', { user: user.id })
        .then(response => {
          this.$alertify.success('User deleted')
          this.dialog = false
          this.getUsers()
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to delete!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
        })
    },
    editUser(user) {
      localStorage.setItem('userEdit', JSON.stringify(user))
      this.$router.push('users/edit')
    },
  },
  async mounted() {
    if (this.hasUserRole('Super Admin') || this.hasUserRole('Client Owner')) {
      this.getUsers()
    } else {
      this.loading = false
      this.$router.push({ name: 'misc-not-authorized' })
    }
    this.dataTableKey += 1
  },
}
</script>

